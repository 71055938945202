export default {
    menu: {
        terms: '使用条款',
        policy: '隐私政策',
        refund: '退款政策',
        contact: '与我们联系'
    },
    onboarding: {
        record_your_calls: '随时随地 <br>记录您的 <br>通话',
        get_started: '開始'
    },
    quiz: {
        your_answers_processed: '正在处理您的回答中',
        analyzing_your_profile: '分析您的个人资料',
        generating_possible_options: '正在产生可能的选项',
        preparing_the_best_offer: '正在准备提供最好的服务'
    },
    congratulations: {
        congratulations: '恭喜',
        enter_your_email: '输入您的电子邮件，并获得访问所有功能的权限',
        your_email: '您的电子邮件',
        enter_valid_email: '輸入一個有效的電子郵件地址',
        get_access: '立即使用',
        we_respect_your_privacy: '我们尊重您的隐私，并致力于保护您的个人资料'
    },
    support: {
        contact_customer_support: '联系客户支持',
        your_name: '您的名字',
        minimum_length: '最小长度- {count}符号',
        email_address: '邮件地址',
        reason_for_inquiry: '询问原因',
        your_message: '您的信息',
        send: '发送'
    },
    support_reason: {
        order_not_received: '未收到订单',
        billing_inquiries: '账单查询',
        subscription_cancellation: '取消订阅',
        technical_difficulties: '技术问题',
        other_question: '其它问题',
    },
    validation: {
        invalid_value: '无效值'
    }
};