import Vue from 'vue';
import Vuex from 'vuex';
import plugins from '@/plugins/vuex';

import appStore from './app-store';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [plugins],
  modules: {
    appStore
  }
});
