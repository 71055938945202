import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        Vue.axios.interceptors.response.use(response => response, async error => Promise.reject(error));
    },

    setHeader() {
        Vue.axios.defaults.headers.common = {
            Accept: 'application/json',
            'Accept-Language': window.navigator.language
        };
    },

    get(resource, config = null) {
        return new Promise((resolve, reject) => {
            return Vue.axios.get(resource, config).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    post(resource, params = null) {
        return new Promise((resolve, reject) => {
            return Vue.axios.post(resource, params).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },

    put(resource, params = null) {
        return new Promise((resolve, reject) => {
            return Vue.axios.put(resource, params).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    patch(resource, params = null) {
        return new Promise((resolve, reject) => {
            return Vue.axios.patch(resource, params).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    delete(resource, config = null) {
        return new Promise((resolve, reject) => {
            return Vue.axios.delete(resource, config).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export default ApiService;
