export default {
    menu: {
        terms: 'Terms of Use',
        policy: 'Privacy Policy',
        refund: 'Refund Policy',
        contact: 'Contact us'
    },
    home: {
        record_your_calls: 'Record Your Calls anywhere, anytime',
        record_and_save_any_calls: 'Record and save any calls on your iPhone. The app is great for interviews and business talks.',
        try_for_free: 'Try for free',
        reviews: 'reviews',
        app_in_numbers: 'Call Recorder in Numbers',
        user_friendly_interface: 'A user-friendly interface and simple features helped us hit all the milestones you see below.',
        business_app: 'Business App',
        across_countries: 'Across 25 countries <br>and 4 continents',
        downloads: 'Downloads',
        that_ensures_growth: 'That ensures more than <br>80% growth every month',
        calls_recorded: 'Calls Recorded',
        including_incoming_and_outgoing_calls: 'Including incoming <br>and outgoing calls',
        core_features: 'Core Features',
        unlimited_calls: 'Unlimited Calls, High Quality, Sharing, Multi Language App.',
        unlimited_call_recordings: 'Unlimited Call <br>Recordings',
        high_quality: 'High Quality <br>Recordings',
        share_recordings: 'Share Recordings <br>to Anyone',
        multi_language_app: 'Multi Language <br>Application',
        people_love_app: 'People love CallRecorder',
        become_a_member: 'Become a member of our global community',
        app_providing_quality: 'CallRecorder providing prime-quality call recording service all over the world.',
    },
    onboarding: {
        record_your_calls: 'Record your calls anywhere anytime',
        easiest_way:'The easiest way to record incoming and outgoing calls',
        get_started: 'Get started',
        continue: 'Continue'
    },
    try: {
        business_app: 'Business App',
        description: 'Recording incoming and outgoing calls was never so easy! Record any calls in few taps and access conversations anywhere, anytime!',
        get_it_now: 'Get it NOW',
        watch_video: 'Watch video',
        video: 'Video',
        watch_how_it_works: 'Watch how it works',
        thirty_seconds_video: '30 seconds video with main functions of our app. Look and download',
        main_functions: 'Main functions',
        our_features: 'Our features',
        how_it_works: 'How it works?',
        comments: 'Comments',
        people_about_us: 'People about us',
        all_our_users_love_us: 'All our users love us',
        email: 'Email',
        enter_your_email_to_get_access: 'Enter your email to get your access details',
        by_continuing_you_agree: 'By continuing you indicate that you\'ve read and agree to our',
        and: 'and',
        and_give_permission_to_send_content: 'and give your permission to send you an email with content',
        features: {
            replay_and_rename_records: 'Replay <span class="text-red">&</span> rename records',
            change_name_of_audio: 'Change the name of an audio recording quickly and easily in one click',
            practical_recording: 'Practical recording in few taps',
            start_recording_in_one_click: 'Already calling? Start recording in one click and talk. Don\'t let anything distract you',
            share_and_export: 'Share & export to <span class="text-red">any devices</span>',
            there_are_no_deadlines: 'There are no deadlines in terms of your access',
            record_incoming_call: 'Record <span class="text-red">incoming call</span>',
            record_incoming_calls_in_one_click: 'Record incoming calls in one click. You don\'t need to drop the call to do this',
            record_outcoming_call: 'Record <span class="text-red">outcoming call</span>',
            start_recording_and_call: 'Start recording and call. All records remain strictly confidential',
            recording_history: 'Recording <span class="text-red">history</span>',
            history_of_all_entries: 'History of all entries. This will allow you to keep everything in one place',
        }
    },
    quiz: {
        your_answers_processed: 'Your answers are being processed',
        analyzing_your_profile: 'Analyzing your profile',
        generating_possible_options: 'Generating possible options',
        preparing_the_best_offer: 'Preparing the best offer'
    },
    congratulations: {
        congratulations: 'Congratulations',
        enter_your_email: 'Enter your email to start using Call Recorder',
        your_email: 'Your email',
        enter_valid_email: 'Enter a valid email address',
        get_access: 'Get Access',
        we_respect_your_privacy: 'We respect your privacy and are committed to protecting your personal data'
    },
    support: {
        contact_customer_support: 'Contact customer support',
        your_name: 'Your name',
        minimum_length: 'Minimum length - {count} symbols',
        email_address: 'Email address',
        reason_for_inquiry: 'Reason for your inquiry',
        your_message: 'Your message',
        send: 'Send'
    },
    support_reason: {
        order_not_received: 'Order not received',
        billing_inquiries: 'Billing inquiries',
        subscription_cancellation: 'Subscription cancellation',
        technical_difficulties: 'Technical difficulties',
        other_question: 'Other question'
    },
    validation: {
        invalid_value: 'Invalid value'
    }
};