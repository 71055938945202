<template>
  <div id="app">
    <component :is="layout" class="site-layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import md5 from 'md5';
import {mapGetters} from 'vuex';
import config from './config';

export default {
  async beforeMount() {
    if (!this.$cookies.get('auth_user_hash')) {
      await this.setAuthUserHashCookie();
    }
    if (!this.$cookies.get('app_key')) {
      await this.setAppKeyCookie();
    }
    if (this.$route.query.fbclid) {
      this.setFbclidCookie();
    }

    this.initGoogleOptimize();
    this.initAmplitude();
  },

  computed: {
    ...mapGetters({
      userHostData: 'appStore/userHostData'
    }),
    layout() {
      return this.$route.meta.layout || 'app-layout'
    }
  },
  methods: {
    async setAuthUserHashCookie() {
      await this.$store.dispatch('appStore/getUserHostData');
      this.$cookies.set('auth_user_hash', md5(this.userHostData.uag + this.userHostData.ip + new Date()), '1m', '/', config.siteHost, true, 'Lax');
    },
    setAppKeyCookie() {
      this.$cookies.set('app_key', config.appKey, '1m', '/', config.siteHost, true, 'Lax');
    },
    setFbclidCookie() {
      this.$cookies.set('auth_fbclid', this.$route.query.fbclid, '1m', '/', config.siteHost, true, 'Lax');
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
