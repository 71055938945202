import Amplitude from 'amplitude-js'
import config from '../config';

const amplitude = Amplitude.getInstance()

export default {
    data() {
        return {
            amplitude,
            clarityInitialized: false
        }
    },
    computed: {
        amplitudeId() {
            return window.location.host === config.siteHost ? config.amplitudeKey.prod : config.amplitudeKey.dev;
        },
        // tiktokPixelId() {
        //     return window.location.host === config.shopHost ? config.tiktokPixelId.prod : config.tiktokPixelId.dev;
        // }
    },
    methods: {
        // init
        // test() {
        //     const onboarding = this.$route.path.includes('onboarding')
        //     console.log(onboarding)
        //     switch (this.$route.path) {
        //         case "/":
        //             console.log('main')
        //             break;
        //
        //         case '/onboarding/':
        //             console.log('onboarding')
        //             break;
        //
        //         case "/try":
        //             console.log('try')
        //             break;
        //         default:
        //             console.log('default')
        //     }
        // },
        initClarity(clarityId) {
            if (this.clarityInitialized) return;

            (function (c, l, a, r, i, t, y) {
                c[a] = c[a] || function () {
                    (c[a].q = c[a].q || []).push(arguments)
                };
                t = l.createElement(r);
                t.async = 1;
                t.src = "https://www.clarity.ms/tag/" + i;
                y = l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t, y);
            })(window, document, "clarity", "script", clarityId);

            this.clarityInitialized = true;

        },
        initGoogleOptimize() {
            let googleOptimize = document.createElement('script');
            googleOptimize.setAttribute('src', `https://www.googleoptimize.com/optimize.js?id=${config.googleOptimizeId}`);
            document.head.appendChild(googleOptimize);
        },
        initAmplitude() {
            amplitude.init(this.amplitudeId);
            amplitude.setDeviceId(this.$cookies.get('auth_user_hash'));
        },
        initFBPixel(id) {
            if (typeof window.fbq !== 'undefined') return false;
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script', '//connect.facebook.net/en_US/fbevents.js');

            window.fbq('init', id, {
                extern_id: this.$cookies.get('auth_uuid'),
                external_id: this.$cookies.get('auth_uuid')
            });
        },
        initTiktok() {
            // const self = this;
            // !function (w, d, t) {
            //     w.TiktokAnalyticsObject = t;
            //     let ttq = w[t] = w[t] || [];
            //     ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'], ttq.setAndDefer = function (t, e) {
            //         t[e] = function () {
            //             t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            //         };
            //     };
            //     for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
            //     ttq.instance = function (t) {
            //         for (let e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
            //         return e;
            //     }, ttq.load = function (e, n) {
            //         let i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
            //         ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {};
            //         let o = document.createElement('script');
            //         o.type = 'text/javascript', o.async = !0, o.src = i + '?sdkid=' + e + '&lib=' + t;
            //         let a = document.getElementsByTagName('script')[0];
            //         a.parentNode.insertBefore(o, a);
            //     };
            //
            //     ttq.load(self.tiktokPixelId);
            //     ttq.page();
            // }(window, document, 'ttq');
        },
        // set event
        amplitudeEvent(event, props = null) {
            // console.log('amplitudeEvent');
            // console.log({event: event, props: props});
            this.amplitude.logEvent(event, props);
        },
        gtagEvent(event, props = null) {
            // console.log('gtagEvent');
            // console.log({event: event, props: props});
            if (props) {
                this.$gtag.event(event, props);
            } else {
                this.$gtag.event(event);
            }
        },
        pixelEvent(event, props = null) {
            // console.log('pixelEvent');
            // console.log({event: event, props: props});
            window.fbq('track', event, props);
        },
        // tiktokEvent(event, props = null) {
        //     // example
        //     const example = {
        //         content_id: '301',
        //         content_type: 'product',
        //         content_name: 'dress',
        //         quantity: 1,
        //         price: 8,
        //         value: 9.2,
        //         currency: 'USD'
        //     };
        //
        //     // ttq.identify({email: this.$cookies.get('auth_email')});
        //     // ttq.track(event, props);
        // }
    }
}