import config from '@/config';

export default {
    namespaced: true,
    state: {
        questions: [],
        menuOpened: false,
        userHostData: null,
        targetingValue: null
    },
    getters: {
        questions(state) {
            return state.questions;
        },
        menuOpened(state) {
            return state.menuOpened;
        },
        userHostData(state) {
            return state.userHostData;
        },
        targetingValue(state) {
            return state.targetingValue;
        }
    },
    mutations: {
        setQuestions(state, questions) {
            state.questions = questions;
        },
        setMenuOpened(state, menuOpened) {
            state.menuOpened = menuOpened;
        },
        setUserHostData(state, userHostData) {
            state.userHostData = userHostData;
        },
        setTargetingValue(state, targetingValue) {
            state.targetingValue = targetingValue;
        }
    },
    actions: {
        getQuestions(context) {
            const lsQuestions = localStorage.getItem('questions');

            if (lsQuestions) {
                context.commit('setQuestions', JSON.parse(lsQuestions));
                return;
            }

            return this.$api.post('https://pay.appitate.biz/api/question/list', {offer_hash: config.offerHash.onboarding})
                .then(response => {
                    context.commit('setQuestions', response.data);
                });
        },
        saveAnswer(context, {questionId, answerIds}) {
            const answer = Array.isArray(answerIds) ? answerIds : [answerIds];

            return this.$api.post('https://pay.appitate.biz/api/answer/save', {
                question_id: questionId, answer_ids: answer, user_hash: $cookies.get('auth_user_hash')
            });
        },
        sendSupportMessage(context, data) {
            return this.$api.post('', data);        // TODO
        },
        preAuth(context, email) {
            let ga = $cookies.get('_ga').split('.');
            ga.splice(0, 2);
            ga = ga.join('.');

            return this.$api.post('https://pay.appitate.biz/api/bankroll/user/pre-auth/', {
                email: email,
                ga_client_id: ga,
                offer_hash: config.offerHash.onboarding,
                user_hash: $cookies.get('auth_user_hash')
            });
        },
        getUserHostData(context) {
            return this.$api.get('https://www.cloudflare.com/cdn-cgi/trace').then(response => {
                const data = response.trim().split('\n').reduce(function (obj, pair) {
                    pair = pair.split('=');
                    return obj[pair[0]] = pair[1], obj;
                }, {});

                context.commit('setUserHostData', data);
            }).catch(() => {
                context.commit('setUserHostData', '');
            });
        },
        getTargetingValue(context) {
            return this.$api.get('https://analyticscall.appitate.biz/admindir/activity.php', {
                params: {key_token: config.targetingValueHash}
            }).then(response => {
                context.commit('setTargetingValue', response.data?.call || response.call);
            });
        }
    }
};
