import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import config from './config';
import VueAxios from 'vue-axios';
// Vue 3rd party plugins;
import {BootstrapVue} from 'bootstrap-vue';
import i18n from './plugins/vue-i18n';
import VueYoutube from 'vue-youtube';
// import VueGtag from 'vue-gtag';
import analyticsMixin from './mixins/analytics';

Vue.config.productionTip = false;

Vue.component('app-layout', () => import('./layouts/AppLayout'));
Vue.component('quiz-layout', () => import('./layouts/QuizLayout'));
Vue.component('try-layout', () => import('./layouts/TryLayout'));
Vue.component('home-layout', () => import('./layouts/HomeLayout'));

Vue.mixin(analyticsMixin);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(VueYoutube);
Vue.use(require('vue-cookies'));
// Vue.use(VueGtag, {config: {id: config.gaId.onboarding.prod}});

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/scss/variables.scss';

new Vue({router, store, i18n, render: h => h(App)}).$mount('#app');
