import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Localisation language list
import en from '../i18n/en.js';
import it from '../i18n/it.js';
import ge from '../i18n/ge.js';
import zh from '../i18n/zh.js';

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, it, ge, zh };

// get current selected language
const lang = window.navigator.language.split('-')[0] || 'en';

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: lang, // set locale
    messages // set locale messages
});

export default i18n;
