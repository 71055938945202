import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'home-layout'
    }
  },
  {
    path: '/try/',
    name: 'try',
    component: () => import('@/views/Try.vue'),
    meta: {
      layout: 'try-layout'
    }
  },
  {
    path: '/onboarding/',
    name: 'onboarding',
    component: () => import('@/views/Onboarding.vue')
  },
  {
    path: '/onboarding/quiz/',
    name: 'quiz',
    component: () => import('@/views/Quiz.vue')/*,
    meta: {
      layout: 'quiz-layout'
    }*/
  },
  {
    path: '/onboarding/congratulations/',
    name: 'congratulations',
    component: () => import('@/views/Congratulations.vue')/*,
    meta: {
      layout: 'quiz-layout'
    }*/
  },
  {
    path: '/onboarding/terms/',
    name: 'terms',
    component: () => import('@/views/policies/Terms.vue'),
    meta: {
      layout: 'quiz-layout'
    }
  },
  {
    path: '/onboarding/policy/',
    name: 'policy',
    component: () => import('@/views/policies/Policy.vue'),
    meta: {
      layout: 'quiz-layout'
    }
  },
  {
    path: '/onboarding/refund/',
    name: 'refund',
    component: () => import('@/views/policies/Refund.vue'),
    meta: {
      layout: 'quiz-layout'
    }
  },
  {
    path: '/onboarding/contact/',
    name: 'contact',
    component: () => import('@/views/policies/Contact.vue'),
    meta: {
      layout: 'quiz-layout'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
