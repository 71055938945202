export default {
    siteHost: 'simple-recorder.com',
    shopHost: 'pay.simple-recorder.com',
    defaultMeta: {
        title: 'Call Recorder',
        meta: [
            {vmid: 'description', name: 'description', content: 'Join #1 social network for call recording.'}
        ]
    },
    appStoreUrl: 'https://icallquiz.onelink.me/WJCU/',
    offerHash: {
        onboarding: 'camfoqlyb6snojfl',
        try: 'qm16lkqvn4u5cno4',
    },
    appKey: 'q2gz3m62qxdjaedysu9b5ertaxebvqyu',
    clarityId: {
        homeId: '9jeafuvomm',
        onboardingId: '9jeclg40e5',
        tryId: '9jeczcbwko'
    },
    targetingValueHash: '07f8e603f7a880dbce100cd265335db0',
    googleOptimizeId: 'OPT-KQ6N6BX',
    amplitudeKey: {
        prod: 'b67dd49e2975d91cc6967dc9cbfcc2d3',
        dev: ''
    },
    fbPixel: {
        onboarding: {
            prod: '659340291713591',
            dev: ''
        },
        try: {
            prod: '1341320299599532',
            dev: ''
        }
    },
    gaId: {
        onboarding: {
            prod: 'UA-187631694-2',
            dev: ''
        },
        try: {
            prod: 'UA-187631694-6',
            dev: ''
        }
    },
    tiktokPixelId: {
        prod: 'C5R6R3923A9UL0EA5US0',
        dev: ''
    }
}
