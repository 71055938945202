export default {
    menu: {
        terms: 'Termini di Utilizzo',
        policy: 'Informativa sulla Privacy',
        refund: 'Politica di Rimborso',
        contact: 'Contattaci'
    },
    onboarding: {
        record_your_calls: 'Registra le tue <br>chiamate ovunque <br>e in qualsiasi momento',
        get_started: 'Iniziare'
    },
    quiz: {
        your_answers_processed: 'Elaborazione delle tue risposte',
        analyzing_your_profile: 'Analisi del tuo profilo',
        generating_possible_options: 'Generazione delle opzioni possibili',
        preparing_the_best_offer: 'Preparazione dell\'offerta migliore'
    },
    congratulations: {
        congratulations: 'Congratulazioni',
        enter_your_email: 'Inserisci la tua e-mail e ottieni l\'accesso a tutte le funzionalità',
        your_email: 'La tua e-mail',
        enter_valid_email: 'Inserire un indirizzo email valido',
        get_access: 'Ottieni Accesso',
        we_respect_your_privacy: 'Rispettiamo la tua privacy e ci impegnamo a proteggere i tuoi dati personali! '
    },
    support: {
        contact_customer_support: 'Contatta l\'assistenza clienti',
        your_name: 'Il tuo nome',
        minimum_length: 'Lunghezza minima - {count} caratteri',
        email_address: 'Indirizzo e-mail',
        reason_for_inquiry: 'Motivo della tua richiesta',
        your_message: 'Il tuo messaggio',
        send: 'Invia'
    },
    support_reason: {
        order_not_received: 'Ordine non ricevuto',
        billing_inquiries: 'Richiesta di fatturazione',
        subscription_cancellation: 'Cancellazione dell\'abbonamento',
        technical_difficulties: 'Difficoltà tecniche',
        other_question: 'Altre domande',
    },
    validation: {
        invalid_value: 'Valore non valido'
    }
};