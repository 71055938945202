export default {
    menu: {
        terms: 'Nutzungsbestimmungen',
        policy: 'Datenschutzrichtlinien',
        refund: 'Erstattungsrichtlinie',
        contact: 'Kontaktieren Sie uns'
    },
    onboarding: {
        record_your_calls: 'Nehmen Sie Ihre <br>Anrufe überall, <br>jederzeit auf',
        get_started: 'Loslegen'
    },
    quiz: {
        your_answers_processed: 'Ihre Antworten werden ausgewertet',
        analyzing_your_profile: 'Analysieren Ihres Profils',
        generating_possible_options: 'Generierung möglicher Optionen',
        preparing_the_best_offer: 'Das beste Angebot vorbereiten'
    },
    congratulations: {
        congratulations: 'Herzlichen Glückwunsch',
        enter_your_email: 'Geben Sie Ihre E-Mail-Adresse ein und Sie erhalten Zugriff auf alle Funktionen',
        your_email: 'Ihre E-Mail-Adresse',
        enter_valid_email: 'Geben sie eine gültige E-Mail-Adresse an',
        get_access: 'Zugang Erhalten',
        we_respect_your_privacy: 'Wir respektieren Ihre Privatsphäre und verpflichten uns, Ihre persönlichen Daten zu schützen'
    },
    support: {
        contact_customer_support: 'Kontakt zum Kundendienst',
        your_name: 'Ihr Name',
        minimum_length: 'Mindestlänge - {count} Zeichen',
        email_address: 'E-Mail Adresse',
        reason_for_inquiry: 'Grund für Ihre Anfrage',
        your_message: 'Ihre Nachricht',
        send: 'Senden'
    },
    support_reason: {
        order_not_received: 'Bestellung nicht erhalten',
        billing_inquiries: 'Fragen zur Rechnung',
        subscription_cancellation: 'Kündigung des Abonnements',
        technical_difficulties: 'Technische Schwierigkeiten',
        other_question: 'Andere Frage',
    },
    validation: {
        invalid_value: 'Ungültiger Wert'
    }
};